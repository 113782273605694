/*------------------------------------*\
    Post Type: Soluzioni ( solution )
    General
\*------------------------------------*/










/*------------------------------------*\
    Post Type: Soluzioni ( solution )
    archive-solution.php
    .post-type-archive-solution {}
\*------------------------------------*/

.post-type-archive-solution {



    .solution-archive {

        .box--title {}// .box--title

        .items {

            padding: $gutter;
            display: flex;
            justify-content: center;
            gap: $gutter;

            @media #{$media-b} { flex-wrap: wrap; }

            .item {

                width: 50rem;
                position: relative;

                @media #{$media-b} { width: 30rem; }

                &:hover,
                &:focus-within {
                    outline: .1rem solid var(--c-accent);
                    outline-offset: 0;
                }

                .link {

                    outline: none;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 5;
                    }

                }// .link

                .image {

                    position: relative;
                    overflow: hidden;

                    .img {

                        display: block;
                        width: 100%;
                        height: auto;

                    }// .img

                    svg {

                        display: block;
                        position: absolute;

                    }// svg

                    .family-icon {

                        height: 3.6rem;
                        z-index: 2;
                        bottom: 12.4rem;
                        right: 3.6rem;

                        @media #{$media-b} {
                            height: 2.6rem;
                            bottom: 8rem;
                            right: 2.4rem;
                        }

                    }// .family-icon

                    .family-draw {

                        width: 8.8rem;
                        height: 8.8rem;
                        z-index: 2;
                        bottom: 1.5rem;
                        right: 5rem;

                        fill: var(--c-grey-dark);

                        @media #{$media-b} {
                            width: 6rem;
                            height: 6rem;
                            bottom: 1rem;
                            right: 3.4rem;
                        }

                    }// .family-draw

                    .bg {

                        // width: 24rem;
                        height: 18rem;
                        bottom: -.1rem;
                        right: 0;
                        z-index: 1;
                        fill: var(--c-white);

                        @media #{$media-b} {
                            height: 12rem;
                        }

                    }// .bg

                }// .image

                .title {

                    margin-top: 2rem;
                    font-size: 3.6rem;
                    line-height: 1.1;
                    font-weight: $f-weight-500;
                    text-transform: uppercase;
                    text-align: center;

                    @media #{$media-b} { font-size: 2.6rem; }

                }// .title

            }// .item

        }// .items

    }// .solution-archive



}// ..post-type-archive-solution {}










/*------------------------------------*\
    Taxonomy: Soluzioni Famiglia ( solution-family )
    Post Type: Soluzioni ( solution )
    taxonomy-solution-family.php
    .archive.tax-solution-family
\*------------------------------------*/

.home {
    .solution-family {
        margin-bottom: $gutter * 3;

        @media #{$media-sm} {
            margin-bottom: $gutter;
        }

        .box--title {
            font-size: 5rem;
            text-transform: uppercase;
            padding: 0;
            margin: 0 auto;
            max-width: 160rem;

            @media #{$media-sm} { 
                padding: $gutter 0 $gutter / 2 0 !important;
            }

            .title2 {
                color: var(--c-corporate-1);
                font-weight: 500;
            }
        }
    }
}

.archive.tax-solution-family, .home {

    .solution-family {

        @media #{$media-bx} { 
            padding: 0 $gutter;
        }

        @media #{$media-sm} {
            margin-top: $gutter / 2;
            padding: 0 $gutter / 2;
        }

        .box--title {
            @media #{$media-bx} { 
                padding: $gutter 0;
            }
        }// .box--title

        .items {

            padding: $gutter 0;
            display: flex;
            flex-wrap: wrap;
            gap: $gutter*2 0;
            margin: 0 auto;
            max-width: 160rem;

            @media #{$media-m} {
                gap: 5rem 0;
            }

            @media #{$media-sm} {
                padding: $gutter / 2 0;
                gap: 3rem 0;
            }

            .item {

                width: 25%;
                position: relative;

                @media #{$media-b} { width: 33%; }
                @media #{$media-m} { width: 50%; }
                @media #{$media-s} { width: 100%; }

                &:hover,
                &:focus-within {
                    outline: .1rem solid var(--c-accent);
                    outline-offset: 0;
                }

                .link {

                    outline: none;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 5;
                    }

                }// .link

                .image {

                    position: relative;
                    overflow: hidden;

                    svg {

                        display: block;
                        position: absolute;

                    }// svg

                    .bg {

                        height: 14rem;
                        bottom: -6rem;
                        right: -6rem;
                        z-index: 0;
                        fill: var(--c-white);
                        transform: scaleX(-1);

                        @media #{$media-b} {
                            height: 12rem;
                        }

                    }// .bg

                    .img {

                        display: block;
                        width: 100%;
                        height: auto;

                    }// .img

                }// .image

                .solution_content {

                    .sector-icon {

                        width: 6rem;
                        height: 6rem;
                        z-index: 2;
                        position: absolute;
                        bottom: 2rem;
                        right: 3rem;
                        fill: var(--c-theme-4);

                        @media #{$media-b} {
                            width: 5rem;
                            height: 5rem;
                            bottom: 0;
                        }

                    }// .sector-icon
                }

                .title {

                    margin-top: 2rem;
                    font-size: 2.2rem;
                    line-height: 1.1;
                    font-weight: $f-weight-500;
                    text-transform: uppercase;
                    z-index: 10;
                    padding: 0 $gutter * 2 0 3rem;

                    @media #{$media-bx} {
                        font-size: 2.2rem;
                    }

                    @media #{$media-sm} {
                        padding-left: 0;
                    }

                    @media #{$media-sm} {
                        font-size: 2.1rem;
                    }

                }// .title

            }// .item

        }// .items

    }// .solution-family

}// .archive.tax-solution-family










/*------------------------------------*\
    Post Type: Soluzioni ( solution )
    single-solution.php
\*------------------------------------*/

.single-solution {



    .solution--single {



        .box--title {}// .box--title


        .solution--content {}// .solution--content

        .content {

            position: relative;
            display: flex;
            align-items: stretch;
            margin-top: $gutter;

            @media #{$media-m} {
                flex-direction: column;
                margin-top: $gutter / 2;
            }

            .content--products {
                width: 64%;

                @media #{$media-m} {
                    width: 100%;
                    margin-top: $gutter;
                }
            }

            .content--image {

                width: 36%;
                position: relative;

                @media #{$media-m} {
                    width: 100%;
                    order: -1;
                }

                img {
                    width: 100%;
                }

                .icon {
                    position: absolute;
                    top: 0;
                    background-color: var(--c-corporate-6);
                    padding: $gutter / 4 $gutter;
                }

                .sector-icon {

                    display: block;
                    width: 12rem;
                    height: 12rem;
                    fill: var(--c-theme-4);

                    @media #{$media-sx} { 
                        width: 8rem;
                        height: 8rem;
                    }

                }// .sector-icon

            }// .content--image

        }// .content


        .solution-product {

            padding: 0 $gutter;
            margin-bottom: $gutter * 2;

            @media #{$media-s} { padding: 0 $gutter / 2; }

            & > .title {

                text-align: center;

                margin-top: 2rem;
                font-size: 3.6rem;
                line-height: 1.1;
                font-weight: $f-weight-500;
                text-transform: uppercase;
                text-align: center;

                @media #{$media-b} { font-size: 2.6rem; }

            }// .title

            .items {

                margin: auto;

                display: grid;
                grid-gap: $gutter;
                grid-template-columns: repeat(5, 1fr);

                @media #{$media-bx} { grid-template-columns: repeat(4, 1fr); }
                @media #{$media-b} { grid-template-columns: repeat(3, 1fr); }
                @media #{$media-m} { grid-template-columns: repeat(4, 1fr); }
                @media #{$media-sm} { grid-template-columns: repeat(3, 1fr); }
                @media #{$media-s} { grid-template-columns: repeat(2, 1fr); grid-gap: $gutter / 1.5; }

                .item {

                    position: relative;
                    display: flex;
                    flex-direction: column;

                    &:hover,
                    &:focus-within {
                        outline: .1rem solid var(--c-accent);
                        outline-offset: 0;
                    }

                    .link {

                        outline: none;
                        word-break: break-word;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 5;
                        }

                    }// .link

                    .image {

                        width: 14rem;
                        height: 13rem;
                        margin: auto;
                        margin-bottom: 2rem;

                        .img {

                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;

                        }// .img

                    }// .image

                    .title {

                        margin-top: auto;
                        font-size: 2rem;
                        line-height: 1.1;
                        font-weight: $f-weight-500;
                        text-transform: uppercase;
                        text-align: center;

                        @media #{$media-sx} { font-size: 1.6rem; }

                    }// .title

                }// .item

            }// .items

        }// .solution-product

    }// .solution--single



}// .single-solution
